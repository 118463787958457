@charset "utf-8";

@import 'blueprint';

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;

  background-color: #dcdcdc;

  #root {
    .login-form {
      display: flex;
      flex: 1 1 auto;
      justify-content: center;
      align-items: center;

      & > * {
        margin: 0 5px;
      }
    }
  }
}

// TODO(vmatt): use standard mixins

@import 'blueprint';

.app {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 20px;

  .settings-card {
    display: flex;
    flex: 1 1 auto;
    flex-wrap: wrap;

    & > * {
      margin: 0 10px;
    }
  }

  .time-series-section {
    display: grid;
    grid-template-columns: 1fr;

    padding: 10px;

    .time-series-card {
      margin: 10px;

      display: grid;
      grid-template-columns: auto auto;

      border-left: 5px solid rgba(0, 0, 0, 0);
      transition: border-left-color 0.2s;

      &.error {
        border-left: 5px solid $red5;
      }

      &.success {
        border-left: 5px solid $green5;
      }

      .card-header {
        justify-self: flex-end;

        display: flex;
        flex: 1 1 auto;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        & > * {
          margin: 2px 0;
        }

        .tags-group {
          display: flex;
          flex: 1 1 auto;
          align-items: center;
          justify-content: space-around;

          & > * {
            margin: 0 2px;
          }
        }

        .event-table {
        }
      }

      .chart-container {
        justify-self: flex-end;

        display: flex;
        flex: 1 1 auto;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.virtualized-table-wrapper {
  overflow: auto;
  padding-right: 15px;
}
